<template>
    <section class="chats-main">
        <div class="row mx-0">
            <div class="menu-left border">
                <div class="row mx-0">
                    <div class="col-12 px-0 tabs-chats">
                        <el-tabs v-model="tabActiva">
                            <el-tab-pane label="Residentes" name="residentes">
                                <chat-residentes 
                                v-if="tabActiva === 'residentes'"
                                @new-chat="enviarNuevoMensaje = !enviarNuevoMensaje"
                                 />
                            </el-tab-pane> 
                            <el-tab-pane label="Grupos" name="grupos">
                                <chat-grupos 
                                v-if="tabActiva === 'grupos'" 
                                @new-grupo="crearGrupo = !crearGrupo"
                                />
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
            <div class="col middle-chat bg-f5">
                <div class="row border-bottom" style="min-height: 57px">
                    <div class="col-12 my-auto cr-pointer">
                        <cardChatResidente
                        v-if="tabActiva === 'residentes'"
                         />
                        <cardChatGrupo 
                        v-if="tabActiva === 'grupos'"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mensajesResidente 
                        v-if="tabActiva === 'residentes' && residenteActivo.id_user"
                        />
                        <mensajesGrupo 
                        v-else-if="tabActiva === 'grupos' && grupoActivo.id_grupo"
                        />
                        <div v-else class="text-center mt-5">
                            <img src="/img/generales/sinMensajes.png" alt="">
                            <p>Seleccionar Conversación</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-side border-left border-top border-bottom">
                <infoResidente v-if="tabActiva === 'residentes' && residenteActivo.id_user" />
                <infoGrupo v-if="tabActiva === 'grupos' && grupoActivo.id_grupo" />
            </div>
        </div>
        <!-- Partials -->
        <modal-ver-img ref="abrirModalVerImg" />
        <!-- "modal" crear grupo-->
        <!-- <div class="crear-grupo" :class="crearGrupoNuevo ? 'activo':'inactivo'">
            <div class="row header mx-0 py-2">
                <div class="col-12 d-flex ">
                    <i class="icon-arrow-left-circle f-25 mr-4 my-auto cr-pointer" @click="crearGrupoNuevo = !crearGrupoNuevo" />
                    <p class="f-17 f-500 my-auto">Nuevo grupo</p>
                </div>
            </div>
            <div class="row mx-0 mt-5">
                <div class="col-12 text-center">
                    <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer mx-auto" style="height:164px;width:164px;background-color:#f1f1f1; color: #868686;border-radius:50%;" />
                </div>
            </div>
            <div class="row mx-0 mt-5 justify-content-center">
                <div class="col-10">
                    <p class="f-12 pl-2 label-inputs">Nombre del grupo</p>
                    <el-input v-model="inputNombre" placeholder="Nombre grupo" size="small" />
                </div>
            </div>
            <div class="row mx-0 mt-4 justify-content-center">
                <div class="col-10">
                    <button class="btn btn-general f-14 f-300 w-100" @click="continuarCreacionGrupo">
                        Continuar
                    </button>
                </div>
            </div>
        </div> -->
        <!-- "modal" agregar residentes al grupo -->
        <div class="agregar-residentes-grupo" :class="agregarResidentesGrupo ? 'activo':'inactivo'">
            <div class="row header mx-0 py-2">
                <div class="col-auto my-auto">
                    <i class="icon-arrow-left-circle f-25 my-auto cr-pointer" @click="agregarResidentesGrupo = !agregarResidentesGrupo" />
                </div>
                <div class="col-8 px-0 my-auto">
                    <el-input v-model="buscarResidente" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
                </div>
                <div class="col-auto px-2 my-auto">
                    <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer opt" @click="verResidentesSeleccionado = !verResidentesSeleccionado">
                        <span class="cantidad px-1">100</span>
                        <i class="icon-account-checked-outline" />
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <div class="contenedor-residentes overflow-auto scroll-none">
                        <div v-for="(data,i) in 20" :key="i" class="row mx-0 mb-3">
                            <div class="col-10 pl-2">
                                <div class="d-middle">
                                    <div class="tipo-user wh-32px d-middle-center rounded-circle mr-2 bg-azulclaro">
                                        <i class="icon-account-outline op-05" />
                                    </div>
                                    <div class="tres-puntos">
                                        <p class="f-15 f-500"> Camilo Ramirez </p>
                                        <p class="f-13 text-86 tres-puntos"> Agrupación 1 - Torre A - Apartamento 203 </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 text-right my-auto">
                                <i class="icon-close f-13 cr-pointer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-4 justify-content-center">
                <div class="col-10">
                    <button class="btn btn-general f-14 f-300 w-100">
                        Añadir
                    </button>
                </div>
            </div>
        </div>
        <!-- "modal" listado residentes seleccionadoss -->
        <div class="listado-residentes-seleccionado" :class="verResidentesSeleccionado ? 'activo':'inactivo'">
            <div class="row header mx-0 py-2">
                <div class="col-12 d-flex ">
                    <i class="icon-arrow-left-circle f-25 mr-4 my-auto cr-pointer" @click="verResidentesSeleccionado = !verResidentesSeleccionado" />
                    <p class="f-17 f-500 my-auto">Residentes seleccionados (100)</p>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <div class="contenedor-residentes overflow-auto scroll-none">
                        <div v-for="(data,i) in 20" :key="i" class="row mx-0 mb-3">
                            <div class="col-10 pl-2">
                                <div class="d-middle">
                                    <div class="tipo-user wh-32px d-middle-center rounded-circle mr-2 bg-azulclaro">
                                        <i class="icon-account-outline op-05" />
                                    </div>
                                    <div class="tres-puntos">
                                        <p class="f-15 f-500"> Karina Ardila </p>
                                        <p class="f-13 text-86 tres-puntos"> Agrupación 1 - Torre A - Apartamento 203 </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 text-right my-auto">
                                <i class="icon-close f-13 cr-pointer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-4 justify-content-center">
                <div class="col-10">
                    <button class="btn btn-general f-14 f-300 w-100">
                        Añadir
                    </button>
                </div>
            </div>
        </div>
        <!-- "modal" enviar nuevo mensaje -->

        <modalNewChatResidente 
        :show="enviarNuevoMensaje"
        @close="enviarNuevoMensaje = false"
        />

        <modalNewGrupo 
        :show="crearGrupo"
        @close="crearGrupo = false"
        />


        <!-- <div class="enviar-mensaje-nuevo" :class="enviarNuevoMensaje ? 'activo':'inactivo'">
            <div class="row header mx-0 py-2">
                <p class="col-12 f-17 f-500">Residentes (325)</p>
                <div class="col-auto my-auto">
                    <i class="icon-arrow-left-circle f-25 my-auto cr-pointer" @click="enviarNuevoMensaje = !enviarNuevoMensaje" />
                </div>
                <div class="col my-auto">
                    <el-input v-model="buscarResidente" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <div class="contenedor-nuevo-mensaje overflow-auto scroll-none">
                        <div v-for="(data,i) in 20" :key="i" class="row mx-0 mb-3 cr-pointer">
                            <div class="col-9 pl-2">
                                <div class="d-middle">
                                    <div class="tipo-user wh-32px d-middle-center rounded-circle mr-2 bg-azulclaro">
                                        <i class="icon-account-outline op-05" />
                                    </div>
                                    <div class="tres-puntos">
                                        <p class="f-15 f-500"> Karina Ardila </p>
                                        <p class="f-13 text-86 tres-puntos"> Agrupación 1 - Torre A - Apartamento 203 </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 text-right">
                                <p class="f-10 text-86"> 12:01 pm </p>
                                <div class="f-10 text-white wh-20px rounded-circle d-middle-center ml-auto mt-1">
                                    2
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components:{
        mensajesResidente: () => import('./components/mensajesResidente'),
        mensajesGrupo: () => import('./components/mensajesGrupo'),
        chatResidentes: () => import('./components/chatResidentes'),
        chatGrupos: () => import('./components/chatGrupos'),
        modalNewChatResidente: () => import('./components/modalNewChatResidente'),
        modalNewGrupo: () => import('./components/modalNewGrupo'),
        cardChatResidente: () => import('./components/cardChatResidente'),
        cardChatGrupo: () => import('./components/cardChatGrupo'),
       
       
        modalVerImg: () => import('./partials/modalVerImg'),
        infoResidente: () => import('./partials/infoResidente'),
        infoGrupo: () => import('./partials/infoGrupo'),
    },
    data(){
        return {
            tabActiva: 'residentes',
            inputSearch: '',
            inputChat:'',
            infoResidente: true,
            crearGrupoNuevo: false,
            agregarResidentesGrupo: false,
            verResidentesSeleccionado: false,
            enviarNuevoMensaje: false,
            crearGrupo: false,
            inputNombre:'',
            buscarResidente:'',
           
        }
    },
    computed:{
        ...mapGetters({
            residenteActivo:'chats/residenteActivo',
            grupoActivo:'chatsGrupos/grupoActivo',
            conjunto:'conjunto/conjunto'
        })
    },
    watch:{
        tabActiva(){
            this.sockets.unsubscribe(`conjunto-${this.conjunto.id}-chat-residente-${this.residenteActivo.id_user}`);
            this.sockets.unsubscribe(`conjunto-${this.conjunto.id}-chat-general`)
            this.$store.commit('chats/initTabResidentes')
            this.$store.commit('chatsGrupos/initTabGrupos')
        }
    },
    methods:{
        verImgUnica(){
            this.$refs.abrirModalVerImg.toggle()
        },
        continuarCreacionGrupo(){
            this.crearGrupoNuevo = !this.crearGrupoNuevo
            this.agregarResidentesGrupo = !this.agregarResidentesGrupo
        }
    }
}
</script>

<style lang="scss" scoped>
.chats-main{
    .menu-left{
        position: relative;
        width: 380px;
        height: calc(100vh - 55px);
        .chat-seleccionado{
            background: #93edcf1a;
        }
        .tipo-user{
            i.icon-buildings{
                &:before{
                    margin-left: 0px;
                }
            }
            i.icon-account-outline{
                font-size: 25px;
            }
        }
        .con-sms{
            p{ color:#109881 !important; }
            .cant-sms{ background: #109881 ; }
        }
    }
    
    .right-side{
        width: 380px;
        height: calc(100vh - 55px);
    }

    .wh-164px{
        max-width: 164px;
        min-width: 164px;
        max-height: 164px;
        min-height: 164px;
    }
    .img-grupo{
        @extend .wh-164px;
        border-radius: 50%;
        border: 2px solid #F5F5F5;
    }
    @keyframes slidein {
        from {
            width: 0px;
        }

        to {
            width: 395px;
        }
    }
    .crear-grupo, .agregar-residentes-grupo, .listado-residentes-seleccionado, .enviar-mensaje-nuevo{
        background: #fff;
        height: calc(100vh - 56px);
        position: absolute;
        top: 0;
        width: 378px;
        left: -393.77px;
        display: none;
        &.activo{
            transform: translateX(395px);
            display: block !important;
            animation-duration: 0.35s;
            // animation-name: slidein;
            z-index: 2;
        }
        .header{
            background: #f1f1f1;
        }
        .contenedor-img-grupo{
            @extend .wh-164px;
            border-radius: 50%;
            border: 2px solid #F5F5F5;
            position:relative;
            .bg-hover{
                @extend .wh-164px;
                border-radius: 50%;
                background: #000;
                opacity: 0;
                position: absolute;
                transition: .35s;
            }
            .img-grupo{
                border: none !important;
            }
            &:hover{
                .bg-hover{
                    opacity: 0.7;
                    transition: .45s;

                }
            }
        }
        .contenedor-residentes{
            height: calc(100vh - 206px);
        }
        .contenedor-nuevo-mensaje{
            height: calc(100vh - 160px);
        }
        .contenedor-residentes-admins{
            height: calc(100vh - 135px);
        }
        .mas-opciones.opt{
            position: relative;
            .cantidad{
                position: absolute;
                color: white;
                background: #FF4D59;
                border-radius: 7px;
                font-size: 8px;
                top: -6px;
                right: -10px;
                font-weight: 500;
            }
        }
    }
    
}
</style>
